@keyframes slideInFromLeftBanner {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRightBanner {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-left {
  animation: slideInFromLeftBanner 1.5s ease-out forwards;
}

.animate-slide-in-right {
  animation: slideInFromRightBanner 1.5s ease-out forwards;
}
